<template>
    <tr>
        <td>
            {{ product[prefs.lang].name }}
            <template v-if="item.options">
                <template v-for="option in item.options">
                    <template v-if="option.enabled && (group.id < 10 || (group.id >= 10 && option.wholesale))">
                        ({{ option['name_'+prefs.lang] }})
                    </template>
                </template>
            </template>
        </td>
        <td>{{ item.q }}</td>
        <td>x</td>
        <td><span :class="getPriceClass">{{ product.price }}₴<br></span> {{ cart.price_final(item) }}₴</td>
        <td>=</td>
        <td><span :class="getPriceClass">{{ cart.price_item_t(item) }}₴<br></span> {{ cart.price_total(item) }}₴</td>
        <td></td>
    </tr>
</template>

<script>
import useCart from "@/use/cart"
import {computed} from "vue"

export default {
    setup(props) {
        const { cart, prefs, products, me, group } = useCart()

        const product = props.item ? products.value.find(i => i.id === props.item.id) : null

        const getPriceClass = computed(() =>
            Number(cart.price_item_t(props.item)) != Number(cart.price_total(props.item)) ? 'crossed' : 'nodisp'
        )

        return { product, cart, prefs, me, group, getPriceClass }
    },
    props: {
        item: Object
    }
}
</script>

<style>
    .nodisp {
        display: none;
    }

</style>